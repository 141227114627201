<template>
    <transition name="notification-fade">
        <div v-if="isActive" class="notification-message">
            <div class="notification-message__text">{{ message }}</div>
        </div>
    </transition>
</template>

<script>
import bus from '@/plugins/bus';

export default {
    data: () => ({
        message: '',
        isActive: false
    }),
    created() {
        bus.$on('show-notification-message', this.show);
        bus.$on('app-clicked', this.hide);
    },
    beforeDestroy() {
        bus.$off('show-notification-message', this.show);
        bus.$off('app-clicked', this.hide);
    },
    methods: {
        show(message) {
            this.message = message;
            this.isActive = true;
        },
        hide() {
            this.message = '';
            this.isActive = false;
        }
    }
};
</script>

<style lang="scss">
.notification-message {
    position: fixed;
    left: 50%;
    top: 30%;
    border-radius: 8px;
    padding: 30px 60px;
    z-index: 9999;
    background: #fff;
    transform: translate(-50%, -50%);
    color: #000;
    box-shadow: 0 0 12px 0 #2D4C70;

    &__text {
        font-weight: 700;
        color: #2D4C70;
    }
}

.notification-fade-enter-active, .notification-fade-leave-active {
    transition: opacity .5s;
}
.notification-fade-enter {
    opacity: 0;
}
</style>
